/* Gallery */
.card-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-color: white !important;
}

.card-cover:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card-text-style {
  padding-top: 5rem;
  margin-top: 15rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  font-style: italic;
  line-height: 1;
  font-weight: 600;
  color: rgb(83, 83, 83);
  text-align: center;
}

.card-text-style-2 {
  padding-top: 1rem;
  margin-top: 1rem;
  font-family: "Open Sans", sans-serif;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: 600;
  color: rgb(83, 83, 83);
  text-align: center;
}

/* Text Style */
.titles-1 {
  font-family: "Yanone Kaffeesatz", serif;
  font-weight: 300;
  font-size: 6rem;
}

.titles-2 {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-size: 2rem;
}

.titles-3 {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-size: 1.2rem;
}

.titles-4 {
  font-family: "Arvo", serif;
  font-weight: 700;
  font-size: 1.5rem;
}

.context-1 {
  font-family: "Arvo", serif;
  font-weight: 400;
  font-size: 1.5rem;
}

.context-2 {
  font-family: "Arvo", serif;
  font-weight: 400;
  font-size: 1.2rem;
}

.context-3 {
  font-family: "Arvo", serif;
  font-weight: 400;
  font-size: 1rem;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.5); /* Blanco semitransparente */
  border-radius: 10px; /* Esquinas redondeadas */
  padding: 10px; /* Espacio interior */
}

.text-shadow-1 {
  text-shadow: 0 0.125rem 0.25rem rgb(0, 0, 0);
}

.text-shadow-2 {
  text-shadow: 0 0.1rem 0.15rem rgba(0, 0, 0, 0.24);
}

.text-shadow-3 {
  text-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.233);
}

.text-background {
  background-color: rgba(214, 231, 217, 0);
  padding: 5px;
}

.text-background-2 {
  background-color: rgba(214, 231, 217, 0);
}

/* Products */
.product-section {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
/* .product-container {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.product-section {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.product-details {
  padding: 2rem;
}

.product-description-picture {
  height: 50rem;
  padding-left: 20rem;
}

.product-description-text {
  padding-top: 15rem;
}

.product-section-header-container {
  height: 100vh;
  overflow: hidden;
  padding: 3rem;
}

.product-section-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5rem;
}

.product-header-section {
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.product-content-header-text {
  color: #f2f2f2;
  justify-content: left;
  align-items: center;
  padding-top: 30vh;
  padding-left: 5%;
  height: 90vh;
}

.product-description-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  height: 100%;
  padding: 5rem;
}

.column-left {
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.column-right {
  color: #615d5d;
  justify-content: left;
  align-items: center;
  padding: 20px;
  padding-top: 25vh;
}

.product-content-description-text {
  color: #615d5d;
  justify-content: left;
  align-items: center;
  padding-top: 30vh;
  padding-left: 5%;
}

.product-icons {
  width: 10vh;
}

.product-key-section-container {
  height: 90vh;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem;
  position: sticky;
}

.product-key-section {
  height: 100%;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: sticky;
}

.align-key-facts {
  padding-top: 6rem;
  text-align: center;
}

.content-row{
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-row {
  padding: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgb(233, 236, 241);
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.product-palette {
  text-align: center;
}

.product-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.product-palette-picture {
  border-radius: 50%;
  height: 35rem;
  padding: 1rem;
}

.product-palette-pantone {
  height: 350px;
}

.card-cover-1 {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #f2f2f2;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card-cover-1:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card-width {
  width: 31vh;
}

.card-style {
  padding-top: 1rem;
  margin-top: 20rem;
}

.without-ul-ol {
  list-style-type: none;
}

.swiper {
  min-height: 20rem;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
}

@media screen and (max-width: 768px) {
  .product-description-section {
    display: block;
  }

  .column-left {
    padding: 10px;
    padding-left: 80%;
    height: 50%;
  }

  .column-right {
    padding: 10px;
    padding-left: 50%;
    height: 50%; /* Ajusta el espaciado para pantallas pequeñas */
  }

  .card-width {
    width: 26vh;
  }

  .card-style {
    padding-top: 1rem;
    margin-top: 15rem;
    line-height: 1;
    font-weight: bold;
  }

  .product-description-picture {
    height: 30rem;
    padding-left: 1rem;
  }

  .product-description-text {
    padding-top: 5rem;
  }

  .text-center {
    text-align: center;
  }

  .align-key-facts {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .titles-1 {
    font-weight: 400;
    font-size: 2.5rem;
  }

  .titles-2 {
    font-weight: 400;
    font-size: 1.5rem;
  }

  .titles-3 {
    font-weight: 400;
    font-size: 1.2rem;
  }

  .titles-4 {
    font-weight: 400;
    font-size: 1.5rem;
  }

  .background-row {
    height: 95%;
    width: 95%;
    padding: 80px;
  }

  .product-palette-picture {
    border-radius: 50%;
    height: 20rem;
    padding: 1rem;
  }

  .product-palette-pantone {
    height: 240px;
  }

  .header-brand-name {
    font-size: 1rem !important;
  }

  .text-header-background {
    background-color: rgba(163, 163, 163, 0.5); /* Blanco semitransparente */
    border-radius: 10px; /* Esquinas redondeadas */
    padding: 10px; /* Espacio interior */
  }
}

@media screen and (min-width: 770px) and (max-width: 900px) {
  .product-description-section {
    display: block;
  }

  .column-left {
    padding: 10px;
    padding-left: 80%;
    height: 50%;
  }

  .column-right {
    padding: 10px;
    padding-left: 50%;
    height: 50%; /* Ajusta el espaciado para pantallas pequeñas */
  }

  .card-width {
    width: 26vh;
  }

  .card-style {
    padding-top: 1rem;
    margin-top: 15rem;
    line-height: 1;
    font-weight: bold;
  }

  .product-description-picture {
    height: 30rem;
    padding-left: 1rem;
  }

  .product-description-text {
    padding-top: 9rem;
  }

  .align-key-facts {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .product-palette-picture {
    border-radius: 50%;
    height: 30rem;
    padding: 1rem;
  }
}
