.home {
  padding: 0 !important;
  color: $white;

  & .home-content {
    z-index: 1020;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    color: rgba($white, 0.5);
    margin-top: rem-default(-120px);
    padding: 0 rem-default(15px);
    text-align: center;

    & h1,
    & h2,
    & h3,
    & h4 {
      color: $white;
      margin: 0 0 rem-default(30px);
      font-weight: 300;

      @include media-breakpoint-down(lg) {
        margin-bottom: rem-default(15px);
      }
    }
    & h1 {
      font-size: rem-default(64px);
      font-weight: 600;

      @include media-breakpoint-down(lg) {
        font-size: rem-default(48px);
      }
      @include media-breakpoint-down(md) {
        font-size: rem-default(36px);
      }
    }
    & h3 {
      font-size: rem-default(32px);

      @include media-breakpoint-down(lg) {
        font-size: rem-default(24px);
      }
      @include media-breakpoint-down(md) {
        font-size: rem-default(18px);
      }
    }
    & p {
      margin-bottom: rem-default(60px);

      @include media-breakpoint-down(lg) {
        margin-bottom: rem-default(30px);
      }
    }
    & .btn {
      &.btn-outline-white {
        border-color: rgba($white, 0.5);

        &:hover {
          color: $dark;
        }
      }
      & + .btn {
        @if $enable-rtl {
          margin-right: rem-default(15px);
        } @else {
          margin-left: rem-default(15px);
        }
      }
    }
  }
}
