.header {
  padding: 0;
  transition: all 0.2s linear;

  & .nav.navbar-nav {
    @include media-breakpoint-down(md) {
      margin: rem-default(7.5px) 0;
    }

    & .nav-item {
      & .nav-link {
        font-size: rem-default(12px);
        line-height: rem-default(20px);
        font-weight: 600;
        padding: rem-default(25px) rem-default(15px);
        display: block;
        transition: all 0.2s linear;

        @include media-breakpoint-down(md) {
          padding: rem-default(10px) rem-default(15px);
        }
      }
      &.dropdown {
        &.open {
          & .dropdown-menu {
            display: block;
          }
        }
        &:hover,
        &:focus {
          & .dropdown-menu {
            @include media-breakpoint-up(lg) {
              display: block;
            }
          }
        }
      }
    }
    &.navbar-end {
      @include media-breakpoint-up(lg) {
        @if $enable-rtl {
          margin-right: auto;
          margin-left: rem-default(-15px);
        } @else {
          margin-left: auto;
          margin-right: rem-default(-15px);
        }
      }
    }
  }
  & .navbar-toggle {
    position: relative;
    padding: rem-default(9px) rem-default(10px);
    margin: rem-default(8px) rem-default(15px);
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: $border-radius;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &:focus {
      outline: none;
    }
    & .icon-bar {
      display: block;
      width: rem-default(22px);
      height: rem-default(2px);
      border-radius: $border-radius;

      & + .icon-bar {
        margin-top: rem-default(4px);
      }
    }
  }
  & .navbar-brand {
    padding: rem-default(25px) rem-default(15px);
    transition: all 0.2s linear;
    display: flex;
    align-items: center;

    @if $enable-rtl {
      margin-right: 0;
      margin-left: $spacer;
    }

    & img {
      display: flex;
      margin: rem-default(-5px) 0;
      max-height: rem-default(40px);
    }
    & .brand-logo {
      // border: 15px solid;
      // border-color: transparent rgba($black, 0.35) rgba($black, 0.7);
      // background: var(--#{$prefix}app-theme);
      margin-top: -5px;
      margin-bottom: -5px;
      border-radius: $border-radius;

      @if $enable-rtl {
        margin-left: rem-default(12px);
      } @else {
        margin-right: rem-default(12px);
      }
    }
    & .brand-text {
      display: block;
      color: var(--#{$prefix}component-color);
      font-size: rem-default(20px);
      line-height: rem-default(20px);
      display: block;
    }

    @include media-breakpoint-up(lg) {
      @if $enable-rtl {
        margin-right: rem-default(-15px);
      } @else {
        margin-left: rem-default(-15px);
      }
    }
  }
  & .navbar-collapse {
    @include media-breakpoint-down(md) {
      margin-left: -$spacer;
      margin-right: -$spacer;
      padding-left: $spacer;
      padding-right: $spacer;
    }
  }
  &.navbar-transparent {
    background-color: rgba(0, 0, 0, 0.322);
    box-shadow: 0 1px rgba($white, 0.25);

    & .navbar-nav {
      & .nav-item {
        & .nav-link {
          color: rgba($white, 0.5);

          &:hover,
          &.active {
            color: var(--#{$prefix}app-theme);
          }
        }
      }
    }
    & .brand-text {
      color: $white;
    }
    & .navbar-toggle {
      border-color: transparent;
      background: rgba($white, 0.2);

      & .icon-bar {
        background: $white;
      }
    }
    & .navbar-collapse {
      @include media-breakpoint-down(md) {
        background: $white;

        & .navbar-nav {
          & .nav-item {
            & .nav-link {
              color: $dark;

              &:hover,
              &.active {
                color: $primary;
              }
            }
            & .dropdown-item {
              color: $gray-900;
            }
          }
        }
      }
    }
    &.navbar-sm {
      background: var(--#{$prefix}component-bg);

      & .brand-text {
        color: var(--#{$prefix}component-color);
      }
      & .navbar-nav {
        & .nav-item {
          & .nav-link {
            color: var(--#{$prefix}component-color);

            &:hover,
            &.active {
              color: var(--#{$prefix}app-theme);
            }
          }
        }
      }
      & .navbar-toggle {
        border-color: transparent;
        background: var(--#{$prefix}light);

        & .icon-bar {
          background: rgba(var(--#{$prefix}component-color-rgb), 0.5);
        }
      }
      @include media-breakpoint-down(lg) {
        & .dropdown-menu {
          & .dropdown-item {
            color: var(--#{$prefix}component-color);

            &:hover,
            &.active {
              color: var(--#{$prefix}app-theme);
            }
          }
        }
      }
    }
  }
  &.navbar-inverse {
    background: lighten($black, 13%);

    & .navbar-nav {
      & .nav-item {
        & .nav-link {
          color: rgba($white, 0.5);

          &:hover,
          &.active {
            color: var(--#{$prefix}app-theme);
          }
        }
      }
    }
    & .brand-text {
      color: $white;
    }
    & .navbar-toggle {
      border-color: transparent;
      background: rgba($white, 0.2);

      & .icon-bar {
        background: $white;
      }
    }
    &.navbar-sm {
      & .navbar-nav {
        & .nav-item {
          & .nav-link {
            &:hover,
            &.active {
              color: var(--#{$prefix}app-theme);
            }
          }
        }
      }
    }
  }
  &.navbar-default {
    background: var(--#{$prefix}component-bg);
    box-shadow: 0 0 16px rgba($black, 0.15);

    & .brand-text {
      color: var(--#{$prefix}component-color);
    }
    & .navbar-nav {
      & .nav-item {
        & .nav-link {
          color: var(--#{$prefix}component-color);

          &:hover,
          &.active {
            color: var(--#{$prefix}app-theme);
          }
        }
      }
    }
    & .navbar-toggle {
      border-color: transparent;
      background: var(--#{$prefix}light);

      & .icon-bar {
        background: rgba(var(--#{$prefix}component-color-rgb), 0.5);
      }
    }
    @include media-breakpoint-down(md) {
      & .dropdown-menu {
        display: block !important;
        padding-left: rem-default(20px);

        & .dropdown-item {
          color: var(--#{$prefix}component-color);

          &:hover,
          &.active {
            color: var(--#{$prefix}app-theme);
          }
        }
      }
    }
  }
  &.navbar-sm {
    box-shadow: 0 0 16px rgba($black, 0.15);

    & .navbar-brand {
      padding: rem-default(15px);
    }
    & .navbar-nav .nav-item .nav-link {
      padding: rem-default(15px);

      @include media-breakpoint-down(lg) {
        padding: rem-default(10px) rem-default(15px);
      }
    }
  }
  &.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    top: 0;
  }
  & .dropdown-menu {
    background: lighten($black, 13%);
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;

    @include media-breakpoint-down(lg) {
      animation: none !important;
    }
    @include media-breakpoint-down(md) {
      background: none;
    }

    & .dropdown-item {
      color: rgba($white, 0.5);
      padding: rem-default(10px) rem-default(15px);

      @include media-breakpoint-down(lg) {
        color: rgba($white, 0.75);
      }

      &:hover,
      &:focus {
        background: lighten($black, 10%);

        @include media-breakpoint-down(lg) {
          background: none;
          color: $white;
        }
      }
      & + .dropdown-item {
        border-top: 1px solid lighten($black, 20%);

        @include media-breakpoint-down(lg) {
          border-top-color: rgba($white, 0.25);
        }
      }
    }
  }
}
