.testimonials {
  padding-bottom: rem-default(30px);

  @include media-breakpoint-down(lg) {
    padding-bottom: rem-default(30px);
  }

  & .item {
    padding-top: rem-default(15px);
  }
  & .carousel-indicators {
    bottom: 0;

    & li {
      background: rgba($white, 0.4);
      border: none;
      width: rem-default(12px);
      height: rem-default(12px);
      margin: rem-default(1px) rem-default(3px);
      border-radius: $border-radius-lg * 2;
      transition: all 0.2s linear;
      list-style-type: none;

      &:hover,
      &:focus {
        background: rgba($white, 0.7);
      }
      &.active {
        background: $white;
      }
    }
  }
  & blockquote {
    border: none;
    text-align: center;
    color: $white;
    position: relative;
    font-weight: 300;
    margin-bottom: rem-default(15px);
    padding: rem-default(15px);
    font-size: rem-default(20px);

    @include media-breakpoint-down(lg) {
      padding: rem-default(15px) rem-default(45px);
    }

    & .fa-quote-left,
    & .fa-quote-right {
      font-size: rem-default(32px);
      position: absolute;
      margin-top: 15px;
      color: #8f8e8e;

      @if $enable-rtl {
        margin-right: 15px;
      } @else {
        margin-left: 15px;
      }
      @include media-breakpoint-down(lg) {
        font-size: rem-default(24px);
      }
    }
    & .fa-quote-left {
      margin-top: -15px;

      @if $enable-rtl {
        margin-right: -43px;
      } @else {
        margin-left: -43px;
      }
    }
  }
  & .name {
    color: $white;
    margin-bottom: rem-default(15px);

    & span {
      @if $enable-rtl {
        margin-right: 5px;
      } @else {
        margin-left: 5px;
      }
    }
  }
}
