.pagination {
  & .page-item {
    & + .page-item {
      @if $enable-rtl {
        margin-right: rem-default(10px);
      } @else {
        margin-left: rem-default(10px);
      }
    }
    & .page-link {
      border-radius: 40px;
    }
    & .text {
      padding: rem-default(10px) 0;
      line-height: $line-height-base * 0.85;
      display: block;
    }
  }
}
.pagination-container {
  margin-top: rem-default(60px);
  padding-top: rem-default(30px);
  border-top: 2px solid $gray-300;

  @include media-breakpoint-down(md) {
    margin-top: rem-default(45px);
    padding-top: rem-default(25px);
  }
}
