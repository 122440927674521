.team {
  text-align: center;
  padding: 0 rem-default(30px);

  @include media-breakpoint-down(lg) {
    padding: rem-default(30px) 0;
  }

  & .image {
    display: inline-block;
    overflow: hidden;
    margin-bottom: rem-default(15px);
    border-radius: $border-radius-lg * 20;

    & img {
      border-radius: $border-radius-lg * 20;
    }
  }
  & .info {
    & .name {
      margin-bottom: rem-default(5px);
    }
    & .title {
      font-weight: 600;
      font-size: rem-default(12px);
      margin-bottom: rem-default(15px);
    }
    & p {
      margin-bottom: rem-default(15px);
      font-size: $font-size-sm;
    }
  }
  & .social {
    & a {
      display: inline-block;
      border: 1px solid $gray-300;
      color: $gray-500;
      text-decoration: none;
      width: rem-default(40px);
      height: rem-default(40px);
      line-height: rem-default(30px);
      padding: rem-default(5px);
      border-radius: $border-radius-lg * 10;

      & + a {
        @if $enable-rtl {
          margin-right: rem-default(5px);
        } @else {
          margin-left: rem-default(5px);
        }
      }
      &:hover,
      &:focus {
        border-color: $gray-400;
        color: $gray-600;
      }
    }
  }
}
