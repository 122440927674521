.theme-panel {
  position: fixed;
  z-index: $app-theme-panel-zindex;
  right: rem-default(-238px);
  top: rem-default(200px);
  width: rem-default(238px);
  border-radius: $border-radius 0 0 $border-radius;
  transition: right 0.2s linear;

  &.active {
    right: 0;
    box-shadow: 0 0 16px rgba($black, 0.15);
  }
  & .theme-collapse-btn {
    position: absolute;
    left: rem-default(-40px);
    top: 50%;
    margin-top: rem-default(-20px);
    width: rem-default(40px);
    height: rem-default(40px);
    line-height: rem-default(40px);
    font-size: $font-size-lg;
    color: var(--#{$prefix}component-color);
    background: var(--#{$prefix}component-bg);
    text-align: center;
    text-decoration: none;
    box-shadow: 0 0 16px rgba($black, 0.15);
    border-radius: $border-radius 0 0 $border-radius;
  }
  & .theme-panel-content {
    padding: rem-default(10px);
    background: var(--#{$prefix}component-bg);
    position: relative;
    border-radius: $border-radius 0 0 $border-radius;
  }
  & .theme-list {
    list-style-type: none;
    margin: 0 rem-default(-5px) rem-default(-5px) 0;
    padding: 0;

    & .theme-list-item {
      float: left;
      margin-right: rem-default(5px);
      margin-bottom: rem-default(5px);

      & > a {
        width: rem-default(32px);
        height: rem-default(32px);
        display: block;
        text-decoration: none;
        position: relative;
        overflow: hidden;
        border-radius: $border-radius;
        transition: all 0.2s linear;
      }
      &.active {
        & > a {
          &:before {
            content: "\f00c";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            font-size: $font-size-base;
            color: $white;
            opacity: 0.75;
            line-height: rem-default(36px);
            text-align: center;

            @include fontawesome();
          }
        }
      }
    }
  }
}
