.progress {
  overflow: initial;
  border-radius: $border-radius-lg * 2;

  & .progress-bar {
    position: relative;
    border-radius: $border-radius-lg * 2;

    & .progress-number {
      position: absolute;
      bottom: 100%;
      background: $dark;
      padding: rem-default(2px) rem-default(6px) rem-default(1px);
      display: block;
      line-height: rem-default(16px);
      margin-bottom: rem-default(5px);
      border-radius: $border-radius;

      @if $enable-rtl {
        left: 0;
      } @else {
        right: 0;
      }

      &:before {
        content: "";
        position: absolute;
        bottom: rem-default(-10px);
        border: 5px solid transparent;
        border-top-color: #333;

        @if $enable-rtl {
          right: 50%;
          margin-right: rem-default(-5px);
        } @else {
          left: 50%;
          margin-left: rem-default(-5px);
        }
      }
    }
  }
}
