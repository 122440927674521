.pace {
  & .pace-progress {
    height: rem-default(3px) !important;
    background: var(--#{$prefix}app-theme) !important;

    & .pace-progress-inner {
      box-shadow: none;
    }
  }
  & .pace-activity {
    top: rem-default(22px) !important;
    right: rem-default(22px) !important;
    width: rem-default(20px) !important;
    height: rem-default(20px) !important;
    border: solid rem-default(3px) transparent !important;
    border-top-color: var(--#{$prefix}app-theme) !important;
    border-left-color: var(--#{$prefix}app-theme) !important;
  }
}
