.action-box {
  & h3 {
    margin-bottom: rem-default(5px);
    margin-top: rem-default(3px);

    @include media-breakpoint-down(lg) {
      margin-bottom: rem-default(10px);
      margin-top: 0;
    }
  }
  & p {
    margin-bottom: rem-default(0px);
    color: rgba($white, 0.75);

    @include media-breakpoint-down(lg) {
      margin-bottom: rem-default(30px);
    }
  }
  & .btn-outline-white {
    border-color: rgba($white, 0.5);
  }
  & .icon-large {
    font-size: rem-default(48px);
    line-height: rem-default(50px);
    width: rem-default(50px);
    text-align: center;

    @if $enable-rtl {
      float: right;
      margin-left: rem-default(20px);
    } @else {
      float: left;
      margin-right: rem-default(20px);
    }
  }
}
