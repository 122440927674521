.quote {
  text-align: center;
  font-size: rem-default(28px);
  font-weight: 300;
  color: $white;

  @include media-breakpoint-down(lg) {
    font-size: rem-default(24px);
  }

  & .fa-quote-left,
  & .fa-quote-right {
    position: relative;
    top: rem-default(-14px);
    font-size: rem-default(20px);
    margin: 0 rem-default(10px);

    @include media-breakpoint-down(lg) {
      font-size: rem-default(16px);
    }
  }
  & small {
    display: block;
    font-size: $font-size-base;
    color: rgba($white, 0.75);
    margin-top: rem-default(20px);
  }
}
